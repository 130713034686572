import React from 'react'

import {
  Col,
  FormGroup,
  FormLayout,
  FormLayoutItem,
  Heading,
  Input,
  List,
  ListItem,
  Paragraph,
  Row,
  Textarea
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import LabelHTMLExample from '../../examples/html/LabelHTMLExample'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="FormLayout"
    components={[{ component: FormLayout }, { component: FormLayoutItem }]}
  >
    <Section>
      <List variant="unordered">
        <ListItem>
          Lomakkeet kootaan <Code>FormLayout</Code>
          -komponentin sisään, jaoteltuna ja otsikoituna{' '}
          <Code>FormLayoutItem</Code>-komponenteilla.
        </ListItem>
        <ListItem>
          Lomakkeet näytetään tavallisesti harmaan laatikon sisässä.{' '}
          <Code>FormLayoutItem</Code> tekee tämän sisällölleen oletuksena.
          Laatikon väri on kuitenkin tietyissä tilanteissa, sisällöstä riippuen
          mahdollista vaihtaa valkoiseksi tai jättää pois kokonaan.
        </ListItem>
      </List>
      <Playground>
        <FormLayout>
          <FormLayoutItem
            header={
              <>
                <Heading
                  level={3}
                  size={3}
                >
                  Lorem ipsum dolor sit amet
                </Heading>
                <Paragraph>
                  Quisque ligula eros ullamcorper quis, lacinia quis facilisis
                  sed sapien.
                </Paragraph>
              </>
            }
          >
            <FormGroup>
              <Input
                aria-describedby="formlayout-example-input-sublabel-1"
                label="Mauris varius diam vitae arcu"
                required
                subLabel="Quisque ligula eros ullamcorper quis"
              />
            </FormGroup>
            <FormGroup noMargin>
              <Textarea
                id="formlayout-example-textarea-1"
                label="Sed arcu lectus auctor vitae"
              />
            </FormGroup>
          </FormLayoutItem>
          <FormLayoutItem
            header={
              <>
                <Heading
                  level={3}
                  size={3}
                >
                  Consectetuer adipiscing elit
                </Heading>
                <Paragraph>
                  Sed arcu lectus auctor vitae, consectetuer et venenatis eget
                  velit. Sed augue orci, lacinia eu tincidunt et eleifend nec
                  lacus. Donec ultricies nisl ut felis, suspendisse potenti.
                </Paragraph>
              </>
            }
          >
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Input
                    id="formlayout-example-input-2"
                    label="Consectetuer velit"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Input
                    id="formlayout-example-input-3"
                    label="Sed augue orci"
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup noMargin>
              <Input
                id="formlayout-example-input-4"
                label="Donec ultricies nisl ut felis"
              />
            </FormGroup>
          </FormLayoutItem>
        </FormLayout>
      </Playground>
      <Playground format="html">
        <div className="form-layout">
          <div className="form-layout-item">
            <h3 className="h3">Lorem ipsum dolor sit amet</h3>
            <p>
              Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed
              sapien.
            </p>
            <div className="box bg-light mb-0">
              <div className="form-group mb-0">
                <LabelHTMLExample
                  htmlFor="formlayout-html-example-1"
                  required
                  subLabel="Sub Label"
                  subLabelId="formlayout-html-example-1-sublabel"
                >
                  Label
                </LabelHTMLExample>
                <input
                  aria-describedby="formlayout-html-example-1-sublabel"
                  className="form-control"
                  id="formlayout-html-example-1"
                  placeholder="Placeholder"
                  required
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="form-layout-item">
            <h3 className="h3">Consectetuer adipiscing elit</h3>
            <p>
              Sed arcu lectus auctor vitae, consectetuer et venenatis eget
              velit. Sed augue orci, lacinia eu tincidunt et eleifend nec lacus.
              Donec ultricies nisl ut felis, suspendisse potenti.
            </p>
            <div className="box bg-light mb-0">…</div>
          </div>
        </div>
      </Playground>
    </Section>
  </Content>
)

export default Page
